@font-face {
    font-family: 'Avenir Book';
    src: url('./Avenir-Book.eot');
    src: url('./Avenir-Book.eot?#iefix') format('embedded-opentype'),
        url('./Avenir-Book.woff2') format('woff2'),
        url('./Avenir-Book.woff') format('woff'),
        url('./Avenir-Book.ttf') format('truetype'),
        url('./Avenir-Book.svg#Avenir-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('./Avenir-Medium.eot');
    src: url('./Avenir-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Avenir-Medium.woff2') format('woff2'),
        url('./Avenir-Medium.woff') format('woff'),
        url('./Avenir-Medium.ttf') format('truetype'),
        url('./Avenir-Medium.svg#Avenir-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('./Avenir-Black.eot');
    src: url('./Avenir-Black.eot?#iefix') format('embedded-opentype'),
        url('./Avenir-Black.woff2') format('woff2'),
        url('./Avenir-Black.woff') format('woff'),
        url('./Avenir-Black.ttf') format('truetype'),
        url('./Avenir-Black.svg#Avenir-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('./Avenir-Light.eot');
    src: url('./Avenir-Light.eot?#iefix') format('embedded-opentype'),
        url('./Avenir-Light.woff2') format('woff2'),
        url('./Avenir-Light.woff') format('woff'),
        url('./Avenir-Light.ttf') format('truetype'),
        url('./Avenir-Light.svg#Avenir-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

