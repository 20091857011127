
@import 'fonts/blackersansdisplay/_blackersansdisplay.css';
@import 'fonts/avenir/_avenir.css';

:root {
  // fonts
  --main-font: 'Avenir';
  --main-font-alt: 'Avenir Book';
  --font-titles: 'Blacker Sans Display';
  --font-titles-alt: 'Blacker Sans Display Book';
  --font-titles-regular: 'BlackerSansDisplay-Regular';

  // colors
  --color-primary: #e1f4f3;
  --color-pink: #FEA2C7;
  --color-text: #303338;
}