@font-face {
    font-family: 'Blacker Sans Display';
    src: url('./BlackerSansDisplay-Bold.eot');
    src: url('./BlackerSansDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('./BlackerSansDisplay-Bold.woff2') format('woff2'),
        url('./BlackerSansDisplay-Bold.woff') format('woff'),
        url('./BlackerSansDisplay-Bold.ttf') format('truetype'),
        url('./BlackerSansDisplay-Bold.svg#BlackerSansDisplay-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Blacker Sans Display';
    src: url('./BlackerSansDisplay-Regular.eot');
    src: url('./BlackerSansDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('./BlackerSansDisplay-Regular.woff2') format('woff2'),
        url('./BlackerSansDisplay-Regular.woff') format('woff'),
        url('./BlackerSansDisplay-Regular.ttf') format('truetype'),
        url('./BlackerSansDisplay-Regular.svg#BlackerSansDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Blacker Sans Display';
    src: url('./BlackerSansDisplay-Black.eot');
    src: url('./BlackerSansDisplay-Black.eot?#iefix') format('embedded-opentype'),
        url('./BlackerSansDisplay-Black.woff2') format('woff2'),
        url('./BlackerSansDisplay-Black.woff') format('woff'),
        url('./BlackerSansDisplay-Black.ttf') format('truetype'),
        url('./BlackerSansDisplay-Black.svg#BlackerSansDisplay-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Blacker Sans Display Book';
    src: url('./BlackerSansDisplay-Book.eot');
    src: url('./BlackerSansDisplay-Book.eot?#iefix') format('embedded-opentype'),
        url('./BlackerSansDisplay-Book.woff2') format('woff2'),
        url('./BlackerSansDisplay-Book.woff') format('woff'),
        url('./BlackerSansDisplay-Book.ttf') format('truetype'),
        url('./BlackerSansDisplay-Book.svg#BlackerSansDisplay-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

