html,body{overflow-x:hidden !important}

// imports
@import './base/reset.css';
@import './base/mixins.scss';
@import './base/variables.scss';
@import './base/breakpoints.scss';

@keyframes cloudLoad {
  0% {
    background-position: top 100vh center;
  }
  100% {
    background-position: top 100% center;
  }
}

body {
  font-weight: 300;
  strong {
    font-weight: 600;
  }
  * {
    color: var(--color-text);
    box-sizing: border-box;
    font-family: var(--main-font);
  }
}

button {
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
}

.dlig {
  font-feature-settings: "dlig";
}

.aalt {
  font-feature-settings: "aalt";
}

.letter-pink {
    color:#FFA1C7;
}

.container {
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  @include breakpoint(phone) {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
  @include breakpoint(tablet-lg) {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
  }
  @include breakpoint(desktop-lg) {
    max-width: 1132px;
    margin-left: auto;
    margin-right: auto;
  }
  &.container--sm {
    @include breakpoint(desktop-lg) {
      max-width: 1050px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.btn-hamburger {
  display: block;
  position: relative;
  display: block;
  z-index: 100;
  width: 40px;
  height: 40px;
  margin-left: auto;
  @include breakpoint(phone) {
      display: none;
  }
  &:before,
  &:after,
  span {
      background-color: #FFF;
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      top: 50%;
      border-radius: 3px;
      margin-top: -1px;
      position: absolute;
      left: 50%;
      margin-left: -10px;
      transition: none .3s ease .3s;
      transition-property: transform, top, bottom, right, opacity;
  }
  &:before {
      transform: translateY(-6px);
  }
  &:after {
      transform: translateY(6px);
  }
  &.is-active {
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    span {
        opacity: 0;
    }
  }
}



.members-cards {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include breakpoint(phone) {
    justify-content: flex-start;
  }
  .card {
    width: calc(100%/2 - 9px);
    min-height: 1px;
    margin-right: 0;
    margin-bottom: 45px;
    @include breakpoint(phone) {
      margin-right: 20px;
      max-width: none;
      width: calc(100%/3 - 20px);
    }
    @include breakpoint(tablet-lg) {
      width: calc(100%/4 - 20px);
    }
    &:nth-of-type(2n) {
      margin-right: 0;
      @include breakpoint(phone) {
        margin-right: 20px;
      }
    }
    .card-img-wrapper {
      width: 100%;
      min-height: 1px;
      border-bottom: 5px solid #FFA1C7;
      img {
        width: 100%;
      }
    }
    .card-img-top {
      height: auto;
      max-height: 210px;
      object-fit: contain;
      @include breakpoint(phone) {
        max-height: 400px;
      }
    }
    .card-body {
      overflow: hidden;
      display: flex;
      flex: 1;
      min-height: 1px;
      flex-direction: column;
      @include breakpoint(phone) {
        padding-right: 0px;
      }
      @include breakpoint(tablet-lg) {
        padding-right: 30px;
      }
      .card-title {
        margin: 29px 0 19px;
        font-family: var(--font-titles);
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 28px;
        word-break: break-word;
        @include breakpoint(phone) {
          margin: 24px 0;
          font-size: 30px;
          line-height: 38px;
        }
        @include breakpoint(tablet-lg) {
          margin: 24px 0;
          font-size: 32px;
          line-height: 48px;
        }
        @include breakpoint(desktop-lg) {
          margin: 24px 0;
          font-size: 39px;
          line-height: 48px;
        }
      }
      .card-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #303338;
        span {
          display: block;
        }
        b {
          font-weight: bold;
        }
      }
    }
    &.card--moderator {
      width: 100%;
      @include breakpoint(phone) {
        width: calc(100%/3 - 20px);
      }
      @include breakpoint(tablet-lg) {
        width: calc(100%/4 - 20px);
      }
      .card-body {
        width: calc(100%/2 - 9px);
        @include breakpoint(phone) {
          width: 100%;
        }
      }
      .card-title {
        margin-top: 8px;
        @include breakpoint(phone) {
          margin-top: 24px 0;
        }
      }
      .card-img-wrapper {
        border: none;
        width: calc(100%/2 - 9px);
        @include breakpoint(phone) {
          width: 100%;
        }
        img {
          width: 100%;
        }
        span {
          display: block;
          background: #B3E6B3;
          text-align: left;
          font-size: 8px;
          line-height: 12px;
          padding: 4px 9px 3px;
          letter-spacing: 0.3em;
          text-transform: uppercase;
        }
      }
    }
  }
}

.event-layout {
  .event-intro__video {
    width: 100%;
    height: 197px;
    left: 0;
    @include breakpoint(phone) {
      height: 397px;
    }
    @include breakpoint(tablet-lg) {
      position: absolute;
      bottom: -167px;
      width: 100%;
      height: 619px;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .members-block {
    margin-bottom: 38px; 
    @include breakpoint(tablet-lg) {
      display: flex;
      margin-bottom: 0;
      align-items: flex-start;
    }
    .no-speaker-top{
      line-height: 130%;
      background: white;
      width: 80%;
      font-size: 11px;
      text-align: center;
      font-family: Avenir;
      margin: 0 auto;
      font-weight: normal;
      max-width: 600px;
      letter-spacing: 0.2em;
      box-shadow: 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.005), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.02), 0px 100px 80px rgba(0, 0, 0, 0.03);
      float: none;
      display: block;
      padding: 60px 40px;
      @include breakpoint(tablet-lg) {
        padding: 188px 40px;
        float: right;
      }
    }
    .block-green-border { 
      padding-left: 22px;
      margin-top: 30px;
      border-left: 2px solid #B3E6B3;
      @include breakpoint(phone) {
        padding-left: 22px;
      }
    }
    .members-block__description {
      padding-left: 10px;
      margin: 0 auto 35px;
      @include breakpoint(phone) {
        padding-left: 22px;
        width: 500px;
      } 
      @include breakpoint(phone) {
        padding-left: 22px;
        margin-left: 30px;
      }
      @include breakpoint(tablet-lg) {
        margin-right: 20px;
        margin-left: 22px;
      }
      strong {
        font-weight: 500;
      }
      .no-speaker-top-block p { 
        font-size: 24px;
        font-weight: 300;
        line-height: 36px;
        strong {
          line-height: 36px;
          font-weight: normal;
        }
      }
      p {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
      }
      strong {
        margin-top: 35px;
        font-weight: normal;
        display: block;
        line-height: 24px;
      }
    }
    .members-cards {
      flex: 1;
      .card {
        width: calc(100%/2 - 9px);
        &.card--moderator {
          width: 100%;
          justify-content: space-between;
          @include breakpoint(phone) {
            width: calc(100%/3 - 20px);
            justify-content: normal;
          }
        }
        @include breakpoint(phone) {
          width: calc(100%/3 - 20px);
        }
        &:nth-of-type(2n) {
          margin-right: 0;
          @include breakpoint(phone) {
            margin-right: 20px;
          }
        }
      }
    }
  }
  .event-intro {
    padding-top: 70px;
    padding-bottom: 30px;
    color: black;
    position: relative;
    box-shadow: 4.5288px 3.62304px rgba(0, 0, 0, 0.005), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.02), 0px 100px 80px rgba(0, 0, 0, 0.03);
    @include breakpoint(tablet-lg) {
      padding-top: 90px;
      padding-bottom: 110px;
    }
    .event-intro__palms {
      position: absolute;
      right: 0;
      top: 50%;
      max-width: 360px;
      z-index: 40;
      display: none;
      transform: translateY(-50%);
      @include breakpoint(tablet-lg) {
        display: block;
      }
    }
    &:after {
      content:'';
      position: absolute;
      top: -50vw;
      left: 15px;
      border-radius: 50%;
      width: calc(100vw - 30px);
      height: calc(100vw - 30px);
      transform: rotate(180deg);
      background: linear-gradient(360deg, rgba(255, 239, 255, 0) 0%, rgba(255, 239, 255, 0.0086472) 6.67%, rgba(255, 239, 255, 0.03551) 13.33%, rgba(255, 239, 255, 0.0816599) 20%, rgba(255, 239, 255, 0.147411) 26.67%, rgba(255, 239, 255, 0.231775) 33.33%, rgba(255, 239, 255, 0.331884) 40%, rgba(255, 239, 255, 0.442691) 46.67%, rgba(255, 239, 255, 0.557309) 53.33%, rgba(255, 239, 255, 0.668116) 60%, rgba(255, 239, 255, 0.768225) 66.67%, rgba(255, 239, 255, 0.852589) 73.33%, rgba(255, 239, 255, 0.91834) 80%, rgba(255, 239, 255, 0.96449) 86.67%, rgba(255, 239, 255, 0.991353) 93.33%, #FFEFFF 100%);
    }
    .container {
      position: relative;
      z-index: 100;
    }
    .event-intro__descriptions {
      margin-bottom: 25px;
      .title-pink {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
    &__content {
      .title-pink, time {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin-bottom: 35px;
        color: #FFA1C7;
      }
      h2 {
        font-family: var(--font-titles);
        color: #303338;
        font-weight: 600;
        max-width: 100%;
        font-size: 102px;
        line-height: 102px;
        margin-bottom: 30px;
        writing-mode: vertical-rl;
        @include breakpoint(phone) {
          writing-mode: horizontal-tb;
          line-height: 90px;
          max-width: 890px;
          margin-left: 0;
          font-size: 90px;
          margin-bottom: 42px;
        }
        @include breakpoint(tablet-lg) {
          font-size: 140px;
          line-height: 140px;
        }
        .letter-pink {
          margin-top: 20px;
          @include breakpoint(phone) {
            margin-left: 20px;
          }
        }
      }
      .description {
        font-weight: 300;
        font-size: 24px;
        line-height: 34px;
        color: #000000;
        margin-bottom: 20px;
        @include breakpoint(phone) {
          font-size: 21x;
          line-height: 31px;
        }
        @include breakpoint(tablet-lg) {
          font-size: 24px;
          line-height: 34px;
          
        }
      }
      .members {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #303338;
        margin-bottom: 20px;
        b {
          font-weight: bold;
        }
      }
      .btn--black {
        margin-bottom: 80px;
      }
      .event-intro__top-data {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        justify-content: space-between;
        .title-pink {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 20px;
          time {
            margin-bottom: 0;
          }
        }
      }
      .event-intro__top_right {
        display: none;
        font-size: 28px;
        font-family: var(--font-titles-alt);
        font-style: normal;
        color: var(--color-text);
        font-weight: normal;
        @include breakpoint(phone) {
          display: block;
        }
      }
      
      .event-intro__content__description {
        font-size: 24px;
        line-height: 36px;
        max-width: 552px;
        margin-bottom: 45px;
        p {
          font-size: 24px;
          font-weight: 300;
          strong {
            font-weight: normal;
          }
        } 
      }
    }
  }
  .section-video {
    padding-bottom: 0;
    margin-bottom: -35px;
    @include breakpoint(phone) {
      margin-bottom: -90px;
    }
    @include breakpoint(tablet-lg) {
      margin-bottom: 0;
    }
    .container {
      position: relative;
      padding-bottom: 0px;
      @include breakpoint(tablet-lg) {
        padding-bottom: 571px;
      }
    }
  }
  .section-on-demand {
    padding-bottom: 0;
    .container {
      padding-bottom: 200px!important;
      @include breakpoint(tablet-lg) {
        padding-bottom: 371px!important;
      }
      .no-speaker-block {
        height: 260px;
        display: flex;
        bottom: -50px;
        left: 16px;
        right: 16px;
        width: auto;
        padding: 50px 20px;
        position: absolute;
        align-items: center;
        @include breakpoint(phone) {
          height: 300px;
          bottom: -90px;
        }
        @include breakpoint(tablet-lg) {
          bottom: -180px;
          height: 500px;
          width: 90%;
          padding: 110px 20px 67px;
        }
        p {
          margin: auto;
          width: 100%;
          line-height: 130%;
        }
      }
    }
  }
  .section-post-video {
    padding-top: 90px;
    @include breakpoint(phone) {
      padding-top: 104px;
    }
    @include breakpoint(tablet-lg) {
      padding-top: 264px;
    }
  }
  .pre-event {
    .event-intro, .followed {
      &:after {
        background: linear-gradient(180deg, #E2F3E2 0%, rgba(226, 243, 226, 0) 50%);
      }
    }
    .followed {
      .no-speakers-block{
        background: white;
        width: 80%;
        padding: 110px 20px 67px;
        margin:0 auto;
        box-shadow: 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.005), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.02), 0px 100px 80px rgba(0, 0, 0, 0.03);
        text-align: center;
        p {
          font-family: Avenir;
          letter-spacing: 0.2em;
          font-size: 11px;
          color: #000000;
        }
      }
      .followed__intro {
        &:before {
          -webkit-text-stroke-color: #B3E6B3;
        }
      }
    }
    .members-cards {
      .card {
        .card-img-wrapper {
          border-color: #B3E6B3;
        }
        img {
          width: 100%;
        }
      }
    }
    .event-intro__content {
      h2 {
        margin-right: auto;
        margin-left: auto;
        display: flex;
        align-items: flex-end;
        @include breakpoint(phone) {
          margin-left: 0;
          display: block;
        }
      }
    }
    .title-pink, time {
      color: #78CF78;
    }
    .letter-pink {
      color: #B3E6B3;
    }
  }
}
  
.event-live {
  .event-intro {
    padding-bottom: 0;
    .event-intro__palms {
      bottom: 0;
      top: auto;
      transform: translate(0);
    }
    .container {
      position: relative;
      padding-bottom: 50px;
      @include breakpoint(tablet-lg) {
        padding-bottom: 571px;
      }
    }
    h2 {
      font-size: 42px;
      line-height: 48px;
      writing-mode: horizontal-tb;
      @include breakpoint(tablet-lg) {
        font-size: 140px;
        line-height: 140px;
      }
    }
    .event-intro__top-data {
      margin-bottom: 36px;
      .title-pink {
        display: flex;
        align-items: center;
        time {
          color: #FFA1C7;
        }
        &:before {
          width: 6px;
          height: 6px;
          content: '';
          margin-right: 8px;
          border-radius: 100%;
          background: #F76631;
        }
      }
      .event-intro__top_right {
        display: block;
      }
    }
  }
  .followed {
    background-color: #FBFBFB;
    &:after {
      content: none;
    }
  }
  .members-block {
    .block-green-border {
      border-color: #FFA1C7;
    }
    .members-block__description {
      margin-top: 25px;
      h3 {
        padding-left: 0;
        font-size: 21px;
        line-height: 28px;
        font-family: var(--font-titles);
        @include breakpoint(tablet-lg) {
          padding-left: 0;
          font-size: 42px;
          line-height: 48px;
          font-family: var(--font-titles);
        }
      }
    }
  }
  .event-live__form {
    padding: 50px 0 60px 0;
    background-color: #FBFBFB;
    box-shadow: 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.005), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.02), 0px 100px 80px rgba(0, 0, 0, 0.03);
    @include breakpoint(tablet-lg) {
      padding: 260px 0 108px 0;
    }
    .container {
      @include breakpoint(tablet-lg) {
        display: flex;
      }
    }
    .event-form__titles {
      max-width: 470px;
      margin-bottom: 37px;
      @include breakpoint(tablet-lg) {
        margin-bottom: 0;
      }
      time {
        margin-bottom: 22px;
        display: block;
      }
      .title-pink {
        font-size: 14px;
      }
      h2 {
        font-size: 21px;
        line-height: 28px;
        font-family: var(--font-titles);
        @include breakpoint(tablet-lg) {
          font-size: 56px;
          line-height: 60px;
        }
      }
    }
    .event-form__form {
      width: 100%;
      height: auto;
      margin-right: 30px;
      margin-left: auto;
      @include breakpoint(tablet-lg) {
        width: 500px;
      }
      .event-form {
        .event-form__row {
          margin-bottom: 20px;
          input, textarea {
            height: 52px;
            padding: 5px 10px;
            border: 1px solid #777E89;
            @include placeholder {
              font-size: 11px;
              text-transform: uppercase;
              letter-spacing: 0.2em;
            }
            &:focus {
              border-color: #FFA1C7;
              outline-color: #FFA1C7;
            }
          }
          input {
            width: 296px;
          }
          textarea {
            resize: none;
            width: 100%;
            padding: 15px 10px;
            height: 139px;
          }
          .btn {
            font-size: 11px;
            font-weight: 500;
            padding-left: 60px;
            padding-right: 60px;
            letter-spacing: 0.2em;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .event-live__exhibitors {
    padding: 70px 0 30px 0;
    overflow: hidden;
    @include breakpoint(tablet-lg) {
      padding: 109px 0 70px 0;
    }
    .followed {
      padding: 0;
      background: transparent;
      .followed__intro {
        margin-bottom: 70px;
        &:before {
          content: none;
        }
        h2 {
          margin-bottom: 70px;
        }
      }
    }
    .event-live__exhibitors-titles {
      .title-pink {
        margin-bottom: 30px;
        display: block;
      }
      h2 {
        font-size: 42px;
        line-height: 48px;
        margin-bottom: 65px;
        font-family: var(--font-titles);
        @include breakpoint(tablet-lg) {
          font-size: 102px;
          line-height: 102px;
          margin-bottom: 70px;
        }
      }
    }
  }
  .card {
    &.card--moderator {
      .card-img-wrapper {
        span {
          background-color: #FFA1C7;
        }
      }
    }
  }
}

.followed {
  position: relative;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  @include breakpoint(phone) {
    padding-top: 118px;
    padding-bottom: 120px;
  }
  .followed__intro {
    position: relative;
    margin-bottom: 33px;
    @include breakpoint(phone) {
      margin-bottom: 102px;
    }
    &:before {
      content: '&';
      font-family: var(--font-titles);
      position: absolute;
      top: 0;
      left: -60px;
      z-index: 1;
      font-size: 150px;
      color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #FFA1C7;
      font-feature-settings: "aalt";
    }
  }
  &:after {
    content:'';
    position: absolute;
    top: -169px;
    border-radius: 50%;
    width: 339px;
    height: 338px;
    left: 50%;
    transform: rotate(180deg) translate(50%, 0%);
    background: linear-gradient(360deg, rgba(255, 239, 255, 0) 0%, rgba(255, 239, 255, 0.0086472) 6.67%, rgba(255, 239, 255, 0.03551) 13.33%, rgba(255, 239, 255, 0.0816599) 20%, rgba(255, 239, 255, 0.147411) 26.67%, rgba(255, 239, 255, 0.231775) 33.33%, rgba(255, 239, 255, 0.331884) 40%, rgba(255, 239, 255, 0.442691) 46.67%, rgba(255, 239, 255, 0.557309) 53.33%, rgba(255, 239, 255, 0.668116) 60%, rgba(255, 239, 255, 0.768225) 66.67%, rgba(255, 239, 255, 0.852589) 73.33%, rgba(255, 239, 255, 0.91834) 80%, rgba(255, 239, 255, 0.96449) 86.67%, rgba(255, 239, 255, 0.991353) 93.33%, #FFEFFF 100%);
    @include breakpoint(phone) {
      width: 970px;
      height: 964px;
      top: -482px;
    }
  }
  .container {
    position: relative;
    z-index: 10;
  }
  h2 {
    font-family: var(--font-titles);
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 28px;
    color: #000000;
    z-index: inherit;
    position: relative;
    margin-top: 20px;
    margin-bottom: 33px;
    max-width: 850px;
    z-index: 10;
    @include breakpoint(phone) {
      font-size: 56px;
      line-height: 60px;
      margin-bottom: 72px;
    }
  }
  .followed-text {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #303338;
    margin-bottom: 40px;
  }
  .members-cards {
    .card {
      &.card--moderator {
        flex-direction: column;
        width: calc(100%/2 - 9px);
        @include breakpoint(phone) {
          margin-right: 20px;
          max-width: none;
          width: calc(100%/3 - 20px);
        }
        @include breakpoint(tablet-lg) {
          width: calc(100%/4 - 20px);
        }
        .card-img-wrapper, .card-body {
          width: 100%;
        }
      }
    }
  }
}

.event-partners {
  background: #303338;
  padding-top: 40px;
  overflow: hidden;
  min-height: 700px;
  h4 {
    color: #fff;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
  h2 {
    font-family: var(--font-titles);
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 60px;
    margin-top: 40px;
  }
}

.home, .event-layout, .about-layout, .terms-layout {
  padding-top: 60px;
  @include breakpoint(phone) {
    padding-top: 100px;
  }
}

.event-layout {
  .event-layout-content {
    min-height: 110vh;
  }
}

.holding-page {
  position: relative; 
  .holding-dates-list {
    li {
      margin-bottom: 40px;
      @include breakpoint(tablet-lg) {
        margin-bottom: 21px;
      }
      time {
        font-size: 14px;
        margin-bottom: 5px;
      }
      h3 {
        margin-bottom: 0;
      }
      .btn {
        margin-top: 24px;
      }
    }
  }
  .holding-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    justify-content: center;
    display: flex; 
    overflow: auto;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.6);
    .holding-overlay__close {
      width: 24px;
      height: 24px;
      padding: 6px;
      top: 13px;
      right: 13px;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .holding-overlay__img {
      width: 158px;
      margin-bottom: 34px;
      @include breakpoint(phone) {
        margin-bottom: 39px;
      }
    }
    .holding-overlay__wrapper {
      width: 513px;
      padding: 26px 0;
      @include breakpoint(phone) {
        padding: 100px 0;
        margin: auto;
      }
    }
    .holding-overlay__content {
      position: relative;
      margin: 0 10px 35px 10px;
      padding: 55px 24px 43px 24px;
      background-color: #303338;
      @include breakpoint(phone) {
        margin: 0;
        padding: 63px 66px 67px 40px;
      }
      p, h3 {
        color: #FFF;
        font-size: 21px;
        line-height: 28px;
      }
      p, strong {
        font-size: 14px;
        color: #FFF;
        line-height: 24px;
        margin-bottom: 35px;
      }
      p {
        font-weight: 300;
        &:last-of-type {
          margin-bottom: 0;
        }
        span {
          color: #FFA1C7;
        }
      }
      strong {
        font-weight: 600;
      }
      h3 {
        font-family: var(--font-titles);
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 35px;
      }
    }
  }
  .home-intro {
    height: 100%;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: top 100% center;
      background-repeat: no-repeat;
      animation: cloudLoad 1.6s ease;
      background-size: contain;
      background-image: url('./../images/clouds.png');
    }
    .home-intro__content {
      h2 {
        font-size: 72px;
        margin-bottom: 56px;
      }
    }
  }
  .home-intro__logo {
    width: 146px;
  }
  .home-intro__content {
    h2 {
      margin-bottom: 56px;
    }
    .title-pink, h3, .btn {
      @include breakpoint(tablet-lg) {
        margin-left: 50px;
      }
    }
  }
}

.holding-page-dark {
  padding: 26px 0 30px 0;
  background-color: #303338;
  @include breakpoint(phone) {
    padding: 96px 0 140px 0;
  }
  .holding-page-dark__sign {
    img {
      display: block;
      width: 154px;
      margin-bottom: 10px;
    }
    strong {
      font-weight: 600;
    }
  }
  .holding-page-dark__content {
    margin: 0 auto;
    @include breakpoint(phone) {
      width: 407px;
    }
    > img {
      width: 208px;
      margin-bottom: 34px;
    }
  }
  .holding-page-dark__description {
    * {
      color: #FFF;
      font-size: 14px;
      line-height: 24px;
    }
    p {
      font-weight: 300;
      margin-bottom: 40px;
    }
    .description__quote {
      margin-bottom: 40px;
      p {
        font-weight: 400;
        margin-bottom: 0px;
        font-size: 20px;
        line-height: 28px;
      }
      strong {
        font-weight: 600;
      }
    }
  }
  .holding-page-dark__calendar {
    margin-bottom: 40px;
    p {
      font-weight: 400;
      margin-bottom: 15px;
    }
    a {
      margin-top: 10px;
    }
  }
}

.site-header {
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  display: flex;
  width: 100%;
  z-index: 999;
  transition: all .3s ease;
  background-color: #0F4678;
  box-shadow: 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.005), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.02), 0px 100px 80px rgba(0, 0, 0, 0.03);
  @include breakpoint(phone) {
    height: 100px;
  }
  .container {
    height: auto;
    width: 100%;
    @media (max-width: 767px) {
      max-width: 100%;
    }
    display: flex;
    align-items: center;
  }
  .header__logo {
    width: 107px;
    @include breakpoint(phone) {
      width: 130px;
    }
    img {
      width: 100%;
    }
  }
  .small-nav-mobile {
    padding-left: 9px;
    @include breakpoint(phone) {
      display: none;
    }
    a {
      color: #FFF;
      margin-bottom: 30px;
      display: block;
      width: 100%;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .site-header__nav-wrapper {
    position: fixed;
    width: 100vw;
    right: -100vw;
    top: 0;
    height: 100%;
    z-index: 9999;
    padding: 12px 12px;
    transition: all .3s ease;
    &.active {
      right: 0;
    }
    background: linear-gradient(180.4deg, #0F4678 42.58%, #336493 95.75%);;
    @include breakpoint(phone) {
      margin-left: auto;
      width: auto;
      padding: 0;
      right: 0;
      display: flex;
      position: relative;
      flex-direction: row;
      background: transparent;
    }
    .nav-mobile-top {
      display: flex;
      margin-bottom: 77px;
      @include breakpoint(phone) {
        display: none;
      }
      img {
        width: 107px;
      }
    }
  }
  .site-header__nav {
    padding-left: 9px;
    margin-bottom: 55px;
    @include breakpoint(phone) {
      display: flex;
      padding-left: 0;
      margin-bottom: 0;
    }
    a {
      color: #FFF;
      font-size: 56px;
      display: block;
      margin-bottom: 40px;
      font-family: var(--font-titles);
      font-feature-settings: "dlig";
      &:last-of-type {
        margin-bottom: 0;
      }
      @include breakpoint(phone) {
        font-size: 11px;
        margin-bottom: 0;
        position: relative;
        letter-spacing: 0.1em;
        font-family: var(--main-font);
        text-transform: uppercase;
        margin-left: 40px;
        &:hover {
          color: #FFA1C7;
          &:before {
            background-color: #FFA1C7;
          }
        }
        &:before {
          content: '';
          position: absolute;
          height: 1px;
          bottom: -6px;
          width: 100%;
          background-color: #FFF;
        }
      }
    }
    .btn-hamburger {
      right: 25px;
      top: 25px;
      position: absolute;
    }
  }
}

.site-footer {
  height: 100px;
  background: #000;
  @include breakpoint(phone) {
    height: 261px;
  }
  .container {
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .logo-footer {
    margin-left: auto;
    img {
      height: auto;
      width: 100px;
      @include breakpoint(phone) {
        width: 215px;
      }
    }
  }
  .site-footer__nav {
    display: block;
    a {
      color: #FFF;
      font-size: 10px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-right: 20px;
      @include breakpoint(phone) {
        margin-right: 40px;
      }
    }
  }
}

.events {
  min-height: 400px;
  .content-carousel {
    margin: 0 auto;
    @include breakpoint(tablet-lg) {
      max-width: 1000px;
    }
    .swiper-container {
      overflow: visible;
    }
    .swiper-pagination {
      bottom: auto;
      top: -58px;
      right: 0;
      left: auto;
      width: auto;
      @include breakpoint(tablet-lg) {
        top: -58px;
        right: 0;
      }
      @include breakpoint(desktop-lg) {
        top: -58px;
        right: -90px;
      }
      .swiper-pagination-bullet {
        margin: 0 7px;
        opacity: 1;
        background-color: #0F4678;
        &.swiper-pagination-bullet-active {
          background-color: #FFA1C7;
        }
      }
    }
  }
  .item-carousel {
    margin-right: 32px;
    width: 232px;
    height: auto;
    > a {
      display: flex;
      flex-direction: column;
      height: 100%;
      top: 0;
      transition: all .3s ease;
      position: relative;
      filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
      .item-carousel-header {
        flex: 1;
      }
      &:hover {
        @include breakpoint(tablet-lg) {
          top: -15px;
          filter: drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.3));
          .item-carousel-header {
            box-shadow: none;
          }
        }
      }
    }
    .item-carousel-header {
      background-color: transparent;
      padding: 33px 15px 39px;
      min-height: 325px;
      display: flex;
      transition: box-shadow .3s ease;
      flex-direction: column;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      background-image: url('../images/event-top-semicircle.svg');
      box-shadow: 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 100px 80px rgba(0, 0, 0, 0.07);
      .date {
        color: #777E89;
        font-family: var(--font-titles);
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 28px;
        time {
          color: #777E89;
          font-family: var(--font-titles);
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 28px;
        }
      }
      .speakers-wrapper {
        margin-top: 18px;
      }
      .title-item {
        margin-top: 22px;
        font-family: var(--font-titles);
        font-weight: 600;
        font-size: 39px;
        line-height: 46px;
        display: flex;
        align-items: center;
        color: #303338;
        margin-bottom: 26px;
        margin-bottom: auto;
        @include breakpoint(phone) {
          font-size: 38px;
          line-height: 48px;
        }
        @include breakpoint(tablet-lg) {
          font-size: 30px;
          line-height: 41px;
        }
        @include breakpoint(desktop-lg) {
          font-size: 38px;
          line-height: 48px;
        }
      }
      .name {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000;
        .pink {
          color: #78CF78;
          margin-left: 5px;
        }
      }
    }
    .item-carousel-footer {
      padding: 28px 12px 50px 12px;
      color: #303338;
      background-color: transparent;
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 180px;
      background-position: bottom center;
      background-image: url('../images/events-bg/event.svg');
      @include breakpoint(phone) {
        padding: 51px 35px 50px 17px;
      }
      .btn-demand {
        font-size: 11px;
        margin-top: 28px;
      }
      .item-carousel-footer-title {
        font-style: normal;
        font-weight: normal;
        font-size: 8px;
        line-height: 12px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      .list-names {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
    }
    &.item-carousel-passed {
      .item-carousel-footer {
        background-image: url('../images/events-bg/passed-event.svg');
        h3, p {
          color: #FFF;
        }
      }
      .item-carousel-header {
        .name {
          .pink {
            color: #777E89;
          }
        }
      }
    }
    &.item-carousel-live {
      .date {
        color: #FFA1C7;
        display: flex;
        align-items: center;
        time {
          color: #FFA1C7;
        }
        &:before {
          width: 6px;
          height: 6px;
          content: '';
          margin-right: 8px;
          border-radius: 100%;
          background: #F76631;
        }
      }
      .item-carousel-header {
        .name {
          .pink {
            color: #FFA1C7;
          }
        }
      }
      .item-carousel-footer {
        background-image: url('../images/events-bg/live-event.svg');
      }
    }
  }
}

.form-section {
  position: relative;
  padding: 90px 17px 0;
  &__title {
    text-transform: uppercase;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2em;
    margin-bottom: 18px;
  }
  .btn-submit {
    position: relative;
    z-index: 1;
    border: none;
    width: 221px;
    height: 49px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.2em;
    color: #fff;
    background: #FEA2C7;
    margin: 56px auto 0;
    &:hover {
      background: #F986B4;
    }
    &:active {
      background: #F877AB;
    }
  }
  .form-section__success {
    font-weight: 600;
    font-size: 42px;
    line-height: 48px;
    color: #fff;
    width: 100%;
    position: relative;
    flex-direction: column;
    font-family: var(--font-titles);
    &.active {
      display: block;
    }
    .btn {
      @include breakpoint(tablet-lg) {
        margin-top: 50px;
      }
    }
  }
  &.form-section--alternative {
    padding: 0;
    padding-top: 60px;
    .legals-form {
      display: none;
      @include breakpoint(tablet-lg) {
        display: block;
      }
    }
    .container {
      padding: 0;
    }
    @include breakpoint(tablet-lg) {
      flex: 1;
      min-height: 100vh;
      padding-top: 90px;
      margin-left: 90px;
      padding-bottom: 30px;
      position: relative;
      background: #0F4678;
      &:before {
        content: '';
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        position: absolute;
        right: -3000px;
        background: #0F4678;
      }
      .form-section__title {
        margin-bottom: 45px;
      }
      .container {
        width: 332px;
        padding: 0;
        margin-left: 50px;
        position: relative;
        z-index: 10;
        .btn-submit {
          margin-left: 0;
          margin-top: 20px;
        }
      }
      .legals-form {
        display: block;
        margin-top: 40px;
        p {
          font-size: 10px;
          color: #FFF;
          line-height: 16px;
        }
        a {
          color: #FFF;
          text-decoration: underline;
        }
      }
    }
    input {
      min-width: 320px;
      &:focus {
        outline: 2px solid #FFA1C7;
      }
    }
    .mktoRequiredField {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    label.mktoLabel {
      margin-left: 12px;
      font-weight: 300!important;
    }
    .mktoButtonRow {
      width: 100%;
      max-width: 100%;
      button {
        margin-left: auto;
        margin-right: auto;
        margin-top: 47px;
        @include breakpoint(tablet-lg) {
          margin-left: 0!important;
          margin-top: 22px;
        }
      }
    }
    .mktoCheckboxList {
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        display: none!important;
      }
      input:checked + label {
        &:before {
          background: rgba(255,255,255, 0);
        }
      }
      label {
        height: 27px;
        width: 27px;
        margin: 0;
        position: relative;
        margin-right: 20px;
        background: #FFF;
        background-size: 46%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../icons/check.svg);
        &:before {
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          background: rgba(255,255,255, 0.9);
        }
      }
    }
  }
}

.legal {
  background: #263F5E;
  padding: 58px 0 45px 0;
  margin-top: -24px;
  position: relative;
  display: flex;
  align-items: center;
  @include breakpoint(tablet-lg) {
    display: none;
  }
  p {
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 160%;
    @include breakpoint(phone) {
      font-size: 11px;
    }
    a {
      color: #FFF;
      text-decoration: underline;
    }
  }
}

.reserve-form {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  .form-group {
    width: 100%;
    margin-bottom: 17px;
    .form-control {
      width: 100%;
      max-width: 296px;
      border: none;
      height: 52px;
      display: flex;
      padding: 0 8px;
      align-items: center;
      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      color: #777E89;
      font-size: 11px;
      line-height: 16px;
      @include breakpoint(phone) {
        max-width: 360px;
        font-size: 14px;
      }
      @include placeholder {
        font-size: 13px;
      }
    }
    .checkbox {
      display: flex;
      
    }
  }
}

.btn {
  display: flex;
  letter-spacing: 0.1em;
  align-items: center;
  text-transform: uppercase;
  padding: 13px 13px 12px 13px;
  img {
    margin-left: 10px;
    height: 25px;
    margin-bottom: 1px;
  }
  &.btn--primary {
    color: #FFF;
    background-color: var(--color-pink);
  }
  &.btn--center {
    display: flex;
    justify-content: center;
  }
  &.btn--outline {
    color: #fff;
    border-radius: 3px;
    background: transparent;
    border: 0.5px solid #FFFFFF;
    padding: 0 12px 0 12px;
    background-color: rgba(255, 255, 255, 0.05);
    background-repeat: no-repeat;
    background-position: center right 12px;
    background-size: 20px;
    height: 36px;
    min-width: 160px;
    display: flex;
    font-size: 10px;
    align-items: center;
    outline: none;
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
    &:active {
      outline: none;
      background-color: rgba(255, 255, 255, 0.25);
    }
    svg {
      path {
        stroke: #FFF;
      }
    }
    &.btn--black {
      color: #000;
      border-color: #000;
      svg {
        path {
          stroke: #000;
        }
      }
    }
  }
  &.btn--underline {
    border: none;
    padding: 0 0 5px 0;
    color: #303338;
    position: relative;
    letter-spacing: 0.1em;
    border-bottom: 1px solid #303338;
    &.btn--arrow {
      border-bottom: none;
      svg {
        path {
          stroke: #303338;
        }
      }
      &:before {
        bottom: 0;
        left: 0;
        right: 22px;
        height: 1px;
        content: '';
        position: absolute;
        background: #303338;
      }
      &:hover {
        color: #FFA1C7;
        svg {
          path {
            stroke: #FFA1C7;
          }
        }
        &:before {
          background: #FFA1C7;
        }
      }
    }
    &.btn--white {
      color: #FFF;
      border-bottom: 1px solid #FFF;
      &.btn--arrow {
        border-bottom: none;
        svg {
          path {
            stroke: #FFF;
          }
        }
        &:before {
          bottom: 0;
          left: 0;
          right: 22px;
          height: 1px;
          content: '';
          position: absolute;
          background: #FFF;
        }
        &:hover {
          color: #FFA1C7;
          svg {
            path {
              stroke: #FFA1C7;
            }
          }
          &:before {
            background: #FFA1C7;
          }
        }
      }
    }
  }
  &.btn--calendar {
    display: flex;
    justify-content: space-between;
    svg {
      margin-bottom: 1px;
      height: 15px;
      width: 15px;
      margin-left: 8px;
    }
    &:hover {
      color: var(--color-pink);
      border-color: var(--color-pink);
      svg {
        path {
          stroke: var(--color-pink);
        }
      }
    }
  }
  &.btn--arrow {
    font-size: 10px;
    svg {
      margin-left: 6px;
      font-size: 12px;
      height: 16px;
      width: 14px;
    }
  }
}


.home-intro {
  background: linear-gradient(180.55deg, #0F4678 23.73%, #0F4678 27.95%, #104779 32.17%, #11497A 36.4%, #134C7C 40.62%, #15507E 44.84%, #185481 49.06%, #1B5884 53.28%, #1E5D87 57.5%, #21618A 61.72%, #24658D 65.94%, #26698F 70.16%, #286C91 74.38%, #296E92 78.61%, #2A6F93 82.83%, #2A6F93 87.05%);
  position: relative;
  @include breakpoint(tablet-lg) {
    min-height: 100vh;
  }
  .intro {
    position: relative;
    @include breakpoint(tablet-lg) {
      min-height: 100vh;
    }
    &:before {
      content: '';
      width: 320px;
      height: 320px;
      border-radius: 50%;
      top: 90px;
      left: -160px;
      background: linear-gradient(90deg, #FFA1C7 0%, #FFEFFF 100%);
      transform: matrix(0, -1, -1, 0, 0, 0);
      display: block;
      position: absolute;
      @include breakpoint(phone) {
        top: 66px;
      }
      @include breakpoint(tablet-lg) {
        display: none;
      }
    }
    .home-intro__content-wrapper {
      @include breakpoint(tablet-lg) {
        padding-top: 90px;
      }
    }
    > .container {
      z-index: 999;
      position: relative;
      @include breakpoint(tablet-lg) {
        display: flex;
      }
    }
  }
  &__logo {
    padding-top: 20px;
    padding-bottom: 90px;
    align-items: center;
    @include breakpoint(tablet-lg) {
      padding: 0px 0 45px 
    }
  }
  &__content {
    @include breakpoint(tablet-lg) {
      max-width: 675px;
    }
    span {
      color: #FFA1C7;
    }
    .title-pink {
      display: block;
      margin-bottom: 30px;
    }
    h2 {
      color: #FFF;
      font-size: 72px;
      margin-bottom: 32px;
      font-family: var(--font-titles);
      @include breakpoint(tablet-lg) {
        font-size: 102px;
      }
    }
    .btn {
      padding-top: 0;
      @include breakpoint(phone) {
        margin-left: 55px;
      }
    }
    .description {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
      margin-bottom: 32px;
      @include breakpoint(phone) {
        max-width: 400px;
        margin-left: 55px;
      }
      a {
        color: #FFA1C7;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }  
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 42px;
      line-height: 48px;
      color: #fff;
      font-family: var(--font-titles);
      margin-bottom: 24px;
      span {
        font-family: var(--font-titles);
      }
    }
  }
  &.homepage {
    display: flex;
    position: relative;
    padding-top: 48px;
    padding-bottom: 212px;
    @include breakpoint(phone) {
      padding-top: 132px;
      padding-bottom: 420px;
    }
    &:before {
      content: '';
      height: 124px;
      width: 100%;
      top: 0;
      z-index: 200;
      position: absolute;
      opacity: 0.25;
      background: linear-gradient(180deg, #083864 0%, rgba(15, 70, 120, 0) 100%);
    }
    &:after {
      content: '';
      position: absolute;
      width: 320px;
      height: 160px;
      bottom: 0px;
      left: 50%;
      z-index: 10;
      border-radius: 160px 160px 0 0;
      transform: translateX(-50%);
      background: rgba(255, 255, 255, 0.1);      
      @include breakpoint(phone) {
        width: 600px;
        height: 300px;
        border-radius: 300px 300px 0 0;
      }
      @include breakpoint(tablet-lg) {    
        width: 970px;
        height: 482px;
        border-radius: 482px 482px 0 0;
      }
    }
    .container {
      z-index: 10;
      display: flex;
      width: 100%;
      align-items: center;
      position: relative;
    }
    .home-intro__video {    
      position: absolute;
      top: 0;
      z-index: 1;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: #356595;
      overflow: hidden;
      video {
        position: relative;
        width: 100%;
        height: auto;
        bottom: 0;
        position: absolute;
        @include breakpoint(phone) {
          width: auto;
          height: auto;
          min-width: 100%;
          min-height: 100%;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  &__footer {
    width: 100%;
    height: 170px;
    position: relative;
    background-image: url(../images/flags.png);
    background-repeat: no-repeat;
    background-size: cover;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: - 20vw;
      width: 20vw * 2;
      height: 20vw;
      z-index: 1;
      border-top-left-radius: 20vw * 2;
      border-top-right-radius: 20vw * 2;
      background-color: rgba(255,255,255,0.1);
    }
  }
}


.title-pink {
  font-size: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: var(--color-pink);
  @include breakpoint(phone) {
    font-size: 13px;
  }
}

.section {
  padding: 110px 0;
  box-shadow: 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.005), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.02), 0px 100px 80px rgba(0, 0, 0, 0.03);
  .section__intro {
    @include breakpoint(phone) {
      display: flex;
    }
    .btn {
      margin-top: 27px;
      @include breakpoint(phone) {
        margin-left: auto;
      }
    }
  }
  .section__small-title {
    font-size: 14px;
    letter-spacing: 0.2em;
    margin-bottom: 40px;
    font-weight: 500;
    text-transform: uppercase;
    &.section__small-title--margin {
      margin-bottom: 48px;
      @include breakpoint(phone) {
        margin-bottom: 90px;
      }
    }
  }
  .description {
    font-weight: 300;
    font-size: 14px;
  }
  .section__title {
    font-family: var(--font-titles);
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    line-height: 48px;
    color: #303338;
    margin-top: 12px;
    max-width: 430px;
    margin-bottom: 10px;
    @include breakpoint(phone) {
      font-size: 56px;
      line-height: 64px;
    }
  }
  .section__descriptions {
    max-width: 372px;
    .dlig {
      margin-bottom: 36px;
    }
    .description {
      line-height: 160%;
    }
    .btn {
      margin-top: 40px;
      display: inline-flex;
    }
  }
  &.section--pink {
    background-color: #FFF7E3;
  }
  &.section--black {
    background: linear-gradient(122.1deg, #303338 12.8%, #0B0B0D 80.87%);
    .section__small-title {
      color: #FFF;
    }
    .section__title {
      color: #FFF;
    }
    .description {
      color: #FFF;
    }
  }
}

.site-cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 18px 35px 16px;
  flex-direction: column;
  background: rgb(255,255,255);
  align-items: center;
  justify-content: center;
  display: none;
  box-shadow: 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.005), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.02), 0px 100px 80px rgba(0, 0, 0, 0.03);
  @include breakpoint(phone) {
    flex-direction: row;
    padding: 16px 58px 16px 20px;
  }
  @include breakpoint(taglet-lg) {
    padding: 16px 20px;
  }
  &.site-cookies--active {
    display: flex;
  }
  .btn-close {
    top: 10px;
    right: 10px;
    z-index: 9999;
    position: absolute;
  }
  p {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    @include breakpoint(phone) {
      margin-bottom: 0;
    }
    a {
      text-decoration: underline;
    }
  }
  .btn {
    font-size: 11px;
    padding: 10px 40px;
    @include breakpoint(phone) {
      margin-left: 20px;
    }
  }
  &.dark {
    background-color: #000;
    p, a {
      color: #FFF;
    }
  }
}

.tues-thurs {
  padding-top: 64px;
  background-color: #FBFBFB;
  padding-bottom: 80px;
  position: relative;
  overflow: hidden;
  @include breakpoint(phone) {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  &:after {
    content: '';
    position: absolute;
    width: 320px;
    height: 160px;
    top: 0px;
    left: 50%;
    border-radius: 160px 160px 0 0;
    transform: rotate(180deg) translateX(50%);
    background: linear-gradient(360deg, rgba(255, 239, 255, 0) 0%, rgba(255, 239, 255, 0.0086472) 6.67%, rgba(255, 239, 255, 0.03551) 13.33%, rgba(255, 239, 255, 0.0816599) 20%, rgba(255, 239, 255, 0.147411) 26.67%, rgba(255, 239, 255, 0.231775) 33.33%, rgba(255, 239, 255, 0.331884) 40%, rgba(255, 239, 255, 0.442691) 46.67%, rgba(255, 239, 255, 0.557309) 53.33%, rgba(255, 239, 255, 0.668116) 60%, rgba(255, 239, 255, 0.768225) 66.67%, rgba(255, 239, 255, 0.852589) 73.33%, rgba(255, 239, 255, 0.91834) 80%, rgba(255, 239, 255, 0.96449) 86.67%, rgba(255, 239, 255, 0.991353) 93.33%, #FFEFFF 100%);
    @include breakpoint(phone) {
      width: 600px;
      height: 300px;
      border-radius: 300px 300px 0 0;
    }
    @include breakpoint(tablet-lg) {    
      width: 970px;
      height: 482px;
      border-radius: 482px 482px 0 0;
    }
  }
  .btn--black {
    margin-top: 32px;
  }
  .container {
    position: relative;
    z-index: 50;
  }
  .section__intro {
    margin-bottom: 85px;
  }
  .section__descriptions {
    max-width: 410px;
  }
  .events-titles-wrapper {
    margin-bottom: 27px;
    @include breakpoint(phone) {
      display: flex;
      align-items: flex-end;
    }
    .section__title {
      margin-bottom: 0;
      flex-shrink: 0;
    }
    span {
      color: #FFA1C7;
      font-size: 11px;
      letter-spacing: 0.2em;
      margin-left: 10px;
      display: inline-block;
      line-height: 110%;
      flex-shrink: 0;
      margin-top: 9px;
      text-transform: uppercase;
      @include breakpoint(phone) {
        display: inline-block;
        margin-top: 0;
      }
    }
  }
  .section__title {
    font-feature-settings: 'salt';
    font-weight: 400;
    font-size: 56px;
    font-family: var(--font-titles-alt);
    display: inline-block;
    @include breakpoint(phone) {
      font-size: 72px;
    }
  }
  .events-section__palms-top-left {
    z-index: 1;
    position: absolute;
    bottom: 380px;
    left: 0;
    height: 230px;
    @include breakpoint(phone) {
      display: none;
    }
  }
  .events-section__palms-left {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 230px;
    @include breakpoint(phone) {
      height: auto;
    }
  }
  .events-section__palms-top-right {
    z-index: 1;
    position: absolute;
    bottom: 380px;
    right: 0;
    height: 230px;
    @include breakpoint(phone) {
      display: none;
    }
  }
  .events-section__palms-right {
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 230px;
    @include breakpoint(phone) {
      height: auto;
    }
  }
  .tues-thurs__block-descriptions {
    margin-top: 22px;
    @include breakpoint(phone) {
      margin-left: 50px;
    }
    .tues-thurs__descriptions {
      font-size: 14px;
      line-height: 24px;
      margin-right: 25px;
      p {
        font-weight: 500;
        margin-bottom: 30px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.cannes {
  position: relative;
  .container {
    z-index: 50;
    position: relative;
  }
  .section__descriptions {
    max-width: 750px;
    .section__title {
      max-width: 100%;
    }
    .description {
      color: #FBFBFB;
      opacity: 0.8;
      @include breakpoint(phone) {
        margin-left: 50px;
      }
    }
    .btn {
      opacity: 0.8;
      font-size: 10px;
      @include breakpoint(phone) {
        margin-left: 50px;
      }
    }
  }
  .cannes__img {
    position: absolute;
    right: 0;
    top: 20%;
    opacity: 0.6;
    width: 239px;
    z-index: 1;
    @include breakpoint(phone) {
      top: 50%;
      width: auto;
      transform: translateY(-40%);
    }
  }
  .cannes__lions-logo-img {
    position: absolute;
    top: 40%;
    max-width: 300px;
    z-index: 1;
    left: 50%;
    transform: translate(-50%, -40%);
    @include breakpoint(phone) {
      top: 50%;
      left: auto;
      right: 200px;
      max-width: 430px;
      transform: translateY(-34%);
    }
  }
}

.virtual-beach {
  padding-top: 130px;
  padding-bottom: 160px;
  @include breakpoint(phone) {
    padding-top: 200px;
    padding-bottom: 220px;
  }
  h2 {
    font-family: var(--font-titles);
    font-style: normal;
    font-weight: normal;
    font-size: 56px;
    line-height: 64px;
    color: #303338;
    margin-top: 12px;
    margin-bottom: 10px;
  }
  .container {
    @include breakpoint(phone) {
      display: flex;
      width: 100%;
    }
  }
  .section__descriptions {
    .description, .btn {
      @include breakpoint(phone) {
        margin-left: 45px;
      }
    }
  }
  .virtual-beach__images {
    margin-left: auto;
    position: relative;
    margin-top: 100px;
    @include breakpoint(phone) {
      margin-top: 0;
      padding-right: 15px;
      display: flex;
    }
    @include breakpoint(tablet-lg) {
      padding-right: 60px;
    }
    img {
      z-index: 99;
      width: 100vw;
      position: relative;
      max-width: 491px;
      @include breakpoint(phone) {
        width: 96%;
        margin-top: auto;
        margin-bottom: auto;
      }
      @include breakpoint(tablet-lg) {
        width: 96%;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    &:before {
      right: -200px;
      top: -60px;
      left: 80%;
      z-index: 5;
      bottom: -60px;
      background: #E7EFF7;
      display: block;
      content: '';
      position: absolute;
      @include breakpoint(phone) {
        right: 0;
        left: 100px;
      }
    }
  }
}

.partners {
  position: relative;
  padding-bottom: 10px;
  @include breakpoint(phone) {
    padding-bottom: 180px;
  }
  .section__descriptions {
    max-width: 700px;
    .section__title {
      max-width: none;
    }
  }
  .partners__lemons-left {
    position: absolute;
    left: -12%;
    top: 42%;
    z-index: 1;
    display: none;
    @include breakpoint(phone) {
      display: block;
    }
  }
  .partners__lemons-right {
    position: absolute;
    right: 3%;
    bottom: 6%;
    z-index: 1;
    @include breakpoint(phone) {
      bottom: 40%;
    }
  }
  .container {
    z-index: 99;
    position: relative;
  }
  .partners-container {
    padding: 0;
    max-width: 100%;
    @include breakpoint(phone) {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
    }
    @include breakpoint(tablet-lg) {
      max-width: 980px;
      margin-left: auto;
      margin-right: auto;
    }
    @include breakpoint(desktop-lg) {
      max-width: 1050px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .partners-blocks-wrapper {
    max-width: 700px;
    overflow: auto;
    padding: 0 20px;
    overflow-y: hidden;
    @include breakpoint(phone) {
      padding: 0;
      overflow: visible;
    }
    .partners-blocks {
      display: inline-flex;
      flex-wrap: nowrap;
      padding-bottom: 80px;
      white-space: nowrap;
      @include breakpoint(phone) {
        display: flex;
      }
      li {
        width: 132px;
        flex-shrink: 0;
        margin-right: 20px;
        background: #E5C26A;
        box-shadow:  0px 4.5288px 3.62304px rgba(135, 117, 40, 0.07), 0px 12.5216px 10.0172px rgba(135, 117, 40, 0.11), 0px 30.1471px 24.1177px rgba(135, 117, 40, 0.14), 0px 50px 60px rgba(135, 117, 40, 0.18);;
        @include breakpoint(phone) {
          width: calc(100%/4 - 20px);
          box-shadow: 0px 4.5288px 3.62304px rgba(135, 117, 40, 0.07), 0px 12.5216px 10.0172px rgba(135, 117, 40, 0.11), 0px 30.1471px 24.1177px rgba(135, 117, 40, 0.14), 0px 50px 60px rgba(135, 117, 40, 0.18);
        }
        &:last-child {
          @include breakpoint(phone) {
            margin-right: 0;
          }
        }
        .partner-content {
          padding-top: 132px;     
          height: auto;
          position: relative;
          @include breakpoint(phone) {
            padding-top: 100%;    
          }
          &.partner-content--lions {
            img {
              max-width: 50%;
              max-height: 50%;
            }
          }
        }
        img {
          position: absolute;
          max-width: 80%;
          max-height: 80%;
          top: 50%;
          left: 50%;
          object-fit: contain;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.circle-gradient {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -169px;
    width: 339px;
    height: 338px;
    left: 50%;
    border-radius: 50%;    
    transform: rotate(180deg) translate(50%, 0%);
    background: linear-gradient(180deg, #E2F3E2 0%, rgba(226, 243, 226, 0) 50%);
    @include breakpoint(phone) {
      width: 970px;
      height: 964px;
      top: -482px;
    }
  }
  .container {
    z-index: 10;
    position: relative;
  }
  &.circle-gradient--pink {
    &:after {
      background: linear-gradient(360deg, rgba(255, 239, 255, 0) 0%, rgba(255, 239, 255, 0.0086472) 6.67%, rgba(255, 239, 255, 0.03551) 13.33%, rgba(255, 239, 255, 0.0816599) 20%, rgba(255, 239, 255, 0.147411) 26.67%, rgba(255, 239, 255, 0.231775) 33.33%, rgba(255, 239, 255, 0.331884) 40%, rgba(255, 239, 255, 0.442691) 46.67%, rgba(255, 239, 255, 0.557309) 53.33%, rgba(255, 239, 255, 0.668116) 60%, rgba(255, 239, 255, 0.768225) 66.67%, rgba(255, 239, 255, 0.852589) 73.33%, rgba(255, 239, 255, 0.91834) 80%, rgba(255, 239, 255, 0.96449) 86.67%, rgba(255, 239, 255, 0.991353) 93.33%, #FFEFFF 100%);
    }
  }
  &.circle-gradient--reverse {
    &:after {
      top: auto;
      bottom: -169px;
      transform: translate(-50%, 0%);
      @include breakpoint(phone) {
        width: 970px;
        height: 964px;
        bottom: -482px;
      }
    }
  }
}

.about-layout {
  .about-intro {
    padding: 0;
    box-shadow: 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.005), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.02), 0px 100px 80px rgba(0, 0, 0, 0.03);
    .container {
      position: relative;
      padding-top: 33px;
      padding-bottom: 85px;
      display: flex;
      flex-direction: column-reverse;
      @include breakpoint(phone) {
        display: block;
        padding-top: 90px;
        padding-bottom: 80px;
      }
      @include breakpoint(tablet-lg) {
        padding-top: 100px;
        padding-bottom: 130px;
      }
      @include breakpoint(desktop-lg) {
        padding-top: 148px;
        padding-bottom: 208px;
      }
    }
  }
  .about-intro__content {
    max-width: 568px;
    h2 {
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #FFA1C7;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 42px;
      line-height: 48px;
      margin-bottom: 20px;
      font-family: var(--font-titles);
      @include breakpoint(phone) {
        font-size: 72px;
        line-height: 76px;
        margin-bottom: 24px;
      }
    }
    .btn {
      display: inline-block;
      width: auto;
      font-size: 10px;
      margin-top: 28px;
      &:not(:first-of-type) {
        margin-top: 25px;
        @include breakpoint(phone) {
          margin-top: 20px;
        }
      }
      @include breakpoint(phone) {
        margin-left: 50px;
      }
    }
    .btns-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }
  .about-intro__video {
    height: 189px;
    width: 100%;
    margin-bottom: 38px;
    @include breakpoint(phone) {
      position: absolute;
      width: 416px;
      height: 459px;
      right: 0;
      z-index: 20;
      margin-bottom: 0;
      bottom: -229.5px;
    }
    @include breakpoint(tablet-lg) {
      bottom: -229.5px;
      width: 670px;
      height: 459px;
    }
    @include breakpoint(desktop-lg) {
      width: 816px;
      height: 459px;
      bottom: -229.5px;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.about-photos {
  padding: 84px 0 60px;
  @include breakpoint(phone) {
    padding: 280px 0 120px;
  }
  .container {
    max-width: 100%;
    padding: 0;
    @include breakpoint(phone) {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
    }
    @include breakpoint(tablet-lg) {
      max-width: 980px;
      margin-left: auto;
      margin-right: auto;
    }
    @include breakpoint(desktop-lg) {
      max-width: 1212px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .about-photos-container {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(desktop-lg) {
      padding-left: 80px;
    }
    img {
      display: block;
      max-width: 330px;
      width: 100%;
      box-shadow: 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.0243888), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 100px 80px rgba(0, 0, 0, 0.07);
      @include breakpoint(phone) {
        width: 330px;
      }
    }
    .img-wrapper {
      margin-bottom: 21px;
      @include breakpoint(tablet-lg) {
        flex-shrink: 0;
        margin-bottom: 0;
      }
      &:nth-of-type(1) {
        @include breakpoint(tablet-lg) {
          margin: 0 100% -25px 90px;
        }
      }
      &:nth-of-type(2) {
        margin-left: auto;
        @include breakpoint(tablet-lg) {
          margin: 0 auto 35px auto;
          width: 100%;
          img {
            margin: 0 auto;
          }
        }
      }
      &:nth-of-type(3) {
        margin-left: 16px;
        @include breakpoint(tablet-lg) {
          margin: 0 20px 35px 28%;
        }
        @include breakpoint(dektop) {
          margin: 0 20px 35px 38%;
        }
      }
      &:nth-of-type(4) {
        margin-left: auto;
        margin-right: 16px;
        @include breakpoint(tablet-lg) {
          margin: 0 0 35px 0px;
        }
      }
      &:nth-of-type(5) {
        margin-left: auto;
        @include breakpoint(tablet-lg) {
          margin: 0 auto -30px 0px;
        }
      }
      &:nth-of-type(6) {
        @include breakpoint(tablet-lg) {
          margin: 0 0 -30px auto;
        }
      }
      &:nth-of-type(7) {
        margin-left: 16px;
        @include breakpoint(tablet-lg) {
          width: 100%;
          margin: 0 0 25px 310px;
        }
      }
      &:nth-of-type(8) {
        margin-left: auto;
        @include breakpoint(tablet-lg) {
          width: 100%;
          margin: 0 0px -140px auto;
          img {
            margin: 0 90px 0 auto;
            @include breakpoint(desktop-lg) {
              margin: 0 180px 0 auto;
            }
          }
        }
      }
      &:nth-of-type(9) {
        margin-left: 16px;
        @include breakpoint(tablet-lg) {
          margin: 0 0px -90px 0;
          width: 100%;
          img {
          }
        }
      }
      &:nth-of-type(10) {
        margin-left: auto;
        margin-right: 16px;
        @include breakpoint(tablet-lg) {
          margin: 0 0px 0px auto;
          img {
            margin: 0 20px 0 auto;
          }
        }
      }
      &:nth-of-type(11) {
        width: 48%;
        @include breakpoint(tablet-lg) {
          width: auto;
          margin: 25px 0px -400px 0;
        }
        img {
          width: 100%;
          @include breakpoint(tablet-lg) {
            width: auto;
          }
        }
      }
      &:nth-of-type(12) {
        width: 48%;
        margin-left: auto;
        @include breakpoint(tablet-lg) {
          width: 100%;
          margin: 0;
        }
        img {
          width: 100%;
          @include breakpoint(tablet-lg) {
            width: auto;
            margin: 0 0 0 80px;
          }
        }
      }
    }
  }
}

.terms-page {
  padding: 60px 0 80px;
  @include breakpoint(phone) {
    padding: 129px 0 200px;
  }
  .terms-intro {
    max-width: 831px;
    h3 {
      font-size: 42px;
      line-height: 46px;
      font-feature-settings: "dlig";
      font-family: var(--font-titles);
      margin-bottom: 35px;
      @include breakpoint(phone) {
        font-size: 72px;
        line-height: 76px;
        margin-bottom: 80px;
      }
    }
  }
  .terms-content {
    max-width: 810px;
    padding-left: 35px;
    @include breakpoint(phone) {
      padding-left: 50px;
    }
    ol {
      counter-reset: item;
      li {
        > ol {
          margin-top: 25px;
          @include breakpoint(phone) {
            margin-top: 30px;
          }
        }
      }
    }
    > ol {
      margin-top: 30px;
    }
    li { 
      display: block;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 25px;
      position: relative;
      @include breakpoint(phone) {
        margin-bottom: 30px;
      }
      ol {
        li {
          ol {
            margin-top: 8px;
            padding-left: 10px;
            @include breakpoint(phone) {
              padding-left: 40px;
              margin-top: 0;
            }
            li {
              margin-bottom: 0;
              padding-left: 20px;
              @include breakpoint(phone) {
                padding-left: 30px
              }
            }
          }
        }
      }
    }

    li:before { 
      content: counters(item, ".") " "; 
      counter-increment: item;
      position: absolute;
      left: -35px;
      width: 50px;
      font-weight: 600;
      @include breakpoint(phone) {
        left: -50px;
      }
    }
  }
}

.talk {
  .container {
    position: relative;
    .talk__logo {
      top: -105px;
      width: 251px;
      height: auto;
      display: none;
      right: -20px;
      position: absolute;
      @include breakpoint(phone) {
        top: -105px;
        display: block;
      }
      @include breakpoint(tablet-lg) {
        top: -155px;
      }
    }
  }
}

.talk-layout {
  .talk-intro {
    padding-bottom: 50px;
    background: #303338;
    @include breakpoint(phone) {
      padding-bottom: 60pxpx;
      padding-top: 130px;
    }
    @include breakpoint(tablet-lg) {
      padding-bottom: 110px;
      padding-top: 220px;
    }
    &:after {
      content: '';
      position: absolute;
      top: -60px;
      width: 341px;
      height: 170px;
      left: 50%;
      border-radius: 105vw 105vw 0 0;    
      background: linear-gradient(360deg, rgba(255, 239, 255, 0) 0%, rgba(255, 239, 255, 0.0086472) 6.67%, rgba(255, 239, 255, 0.03551) 13.33%, rgba(255, 239, 255, 0.0816599) 20%, rgba(255, 239, 255, 0.147411) 26.67%, rgba(255, 239, 255, 0.231775) 33.33%, rgba(255, 239, 255, 0.331884) 40%, rgba(255, 239, 255, 0.442691) 46.67%, rgba(255, 239, 255, 0.557309) 53.33%, rgba(255, 239, 255, 0.668116) 60%, rgba(255, 239, 255, 0.768225) 66.67%, rgba(255, 239, 255, 0.852589) 73.33%, rgba(255, 239, 255, 0.91834) 80%, rgba(255, 239, 255, 0.96449) 86.67%, rgba(255, 239, 255, 0.991353) 93.33%, #FFEFFF 100%);
      transform: rotate(180deg) translate(50%, 0%);
      opacity: 0.2;
      @include breakpoint(phone) {
        top: -240px;
        width: 1690px;
        height: 840px;
      }
    }
    .container {
      z-index: 20;
      position: relative;
    }
    .talk__titles {
      margin-bottom: 50px;
      @include breakpoint(phone) {
        margin-bottom: 64px;
      }
      @include breakpoint(tablet-lg) {
        margin-bottom: 79px;
      }
      .talk__time {
        color: #FFA1C7;
        display: flex;
        font-size: 14px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        align-items: center;
        margin-bottom: 60px;
        &:before {
          width: 6px;
          height: 6px;
          content: '';
          margin-right: 8px;
          border-radius: 100%;
          background: #F76631;
        }
      }
      h3 {
        font-size: 21px;
        line-height: 28px;
        color: #000000;
        font-style: italic;
        margin-bottom: 10px;
        font-feature-settings: 'pnum';
        font-family: var(--font-titles);
        @include breakpoint(phone) {
          font-size: 42px;
          margin-bottom: 20px;
          line-height: 48px;
        }
      }
      h2 {
        font-size: 42px;
        line-height: 48px;
        color: #000000;
        font-feature-settings: 'pnum';
        font-family: var(--font-titles);
        @include breakpoint(phone) {
          font-size: 70px;
          line-height: 80px;
        }
        @include breakpoint(tablet-lg) {
          font-size: 120px;
          line-height: 130px;
        }
      }
    }
  }
  .talk__descriptions {
    color: #FFF;
    * {
      color: #FFF;
    }
    > h4 {
      margin-bottom: 19px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      @include breakpoint(phone) {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 19px;
      }
    }
    .talk__descriptions__block {
      padding-left: 23px;
      margin-left: 10px;
      max-width: 552px;
      border-left: 1px solid #FFA1C7;
      @include breakpoint(phone) {
        padding-left: 34px;
        margin-left: 0;
      }
      ul {
        li {
          margin-bottom: 25px;
          h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            @include breakpoint(phone) {
              font-size: 18px;
              line-height: 28px;
            }
          }
          p {
            font-size: 14px;
            line-height: 24px;
            @include breakpoint(phone) {
              font-size: 18px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
  .talk-video {
    position: relative;
    padding-bottom: 180px;
    background: #303338;
    @include breakpoint(phone) {
      padding-bottom: 279px;
    }
    &:before {
      top: 130px;
      content: '';
      width: 100%;
      height: 237px;
      z-index: 10;
      position: absolute;
      display: block;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 40.62%);
      @include breakpoint(phone) {
        top: 241px;
        height: 357px;
      }
      @include breakpoint(tablet-lg) {
        top: 241px;
        height: 657px;
      }
    }
    iframe {
      width: 100%;
      height: 193px;
      @include breakpoint(phone) {
        height: 350px;
      }
      @include breakpoint(tablet-lg) {
        height: 619px;
      }
    }
    .talk__video {
      z-index: 50;
      position: relative;
    }
  }
}

.post-event {
  > .event-intro {
    .container {
      padding-bottom: 0;
      @include breakpoint(tablet-lg) {
        padding-bottom: 571px;
      }
    }
    &:after {
      background: linear-gradient(180deg, #E7EFF7 0%, rgba(231, 239, 247, 0) 100%);
    }
  }
  .followed {
    &:after {
      background: linear-gradient(180deg, #E7EFF7 0%, rgba(231, 239, 247, 0) 100%);
    }
  }
  .event-intro__top-data {
    .event-intro__top_right {
      display: block;
    }
  }
  .event-intro__content { 
    .event-intro__top-data { 
      .title-pink {
        color: #2467A8;
        time {
          color: #2467A8;
        }
      } 
    }
  }
  .event-intro {
    box-shadow: none;
    @include breakpoint(phone) {
      box-shadow: 4.5288px 3.62304px rgba(0, 0, 0, 0.005), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.02), 0px 100px 80px rgba(0, 0, 0, 0.03);
    }
  }
  .event-intro__top-data {
    .title-pink, time {
      font-size: 14px;
      @include breakpoint(phone) {
        font-size: 14px;
      }
    }
  }
  .event-intro__content {
    h2 {
      font-size: 42px;
      line-height: 48px;
      writing-mode: horizontal-tb;
      @include breakpoint(phone) {    
        font-size: 62px;
        line-height: 68px;
      }
      @include breakpoint(tablet-lg) {  
        font-size: 140px;
        line-height: 140px;
      }
      .letter-pink {
        color: #2467A8;
      }
    }
  }
  .members-block {
    margin-bottom: 35px;
    .block-green-border {
      border-left: 2px solid #2467A8;
    }
  }
  .members-cards {
    .card {
      .card-img-wrapper {
        border-color: #2467A8;
        span { 
          color: #FFF;
          background-color: #2467A8;
        }
      }
      .letter-pink {
        color: #2467A8;
      }
    }
  }
  .section-members {
    background-color: #FBFBFB;
    margin-bottom: -35px;
    @include breakpoint(phone) {
      margin-bottom: 0;
      background-color: #FBFBFB;
    }
    @include breakpoint(tablet-lg) {
    }
    &:after {
      content: none;
      @include breakpoint(phone) {  
        content: '';
      }
    }
    .event-intro__video {
      position: relative;
      top: 30px;
      @include breakpoint(phone) {
        top: 80px;
      }
      @include breakpoint(tablet-lg) {  
        position: absolute;
        top: auto;
      }
    }
  }
  .followed {
    padding-top: 120px;
    @include breakpoint(phone) {
      padding-top: 144px;
    }
    @include breakpoint(tablet-lg) {
      padding-top: 264px;
    }
    &:after {
      content: none;
      @include breakpoint(phone) {  
        content: '';
      }
    }
    .followed__intro {
      margin-bottom: 33px;
      @include breakpoint(phone) {
        margin-bottom: 50px;
      }
      @include breakpoint(tablet-lg) {
        margin-bottom: 72px;
      }
      &:before {
        content: none;
      }
      .title-pink {
        color: #2467A8;
      }
    }
  }
}

.no-speaker-block{
  background: white;
  width: 80%;
  padding: 110px 20px 67px;
  margin:0 auto;
  box-shadow: 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.005), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.02), 0px 100px 80px rgba(0, 0, 0, 0.03);
  text-align: center;
  p {
    font-family: Avenir;
    letter-spacing: 0.2em;
    font-size: 11px;
    color: #000000;
  }
}